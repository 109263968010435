
@media (max-width:500px ) {
    .Identification h1 {
    font-size: 20px;
    }
    .Identification h3 {
    font-size: 16px
    }
    .Identification h4{
        font-size: 14px;
    }
}