#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.Category .swiper {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .Category .swiper {
    padding: 0 28px !important;
  }
  body[dir="rtl"] .Category .swiper-button-prev {
    right: -6px !important;
    left: auto;
  }
  body[dir="ltr"] .Category .swiper-button-prev {
    left: -6px !important;
    right: auto;
  }
  body[dir="rtl"] .Category .swiper-button-next {
    left: -6px !important;
    right: auto;
  }
  body[dir="ltr"] .Category .swiper-button-next {
    right: -6px !important;
    left: auto;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Category .swiper-pagination {
  display: none !important;
}

.Category .swiper-button-prev,
.Category .swiper-button-next {
  opacity: 1 !important;
}

@media (max-width: 767px) {
  .Category .swiper-button-prev,
  .Category .swiper-button-next {
    display: none;
  }
}

.Category .swiper .swiper-button-next:hover,
.Category .swiper .swiper-button-prev:hover {
  color: #007e3d !important;
  /* opacity: 1 !important; */
}

.Category .swiper-button-next:after,
.Category .swiper-button-prev:after {
  font-size: 20px;
  font-weight: bold;
  color: #007e3d;
  /* opacity: 1 !important; */
}

.brand-img {
  transition: 0.2s;
}

.brand-img:hover {
  transform: scale(1.05);
}
