.about .about_info .about_mission .about_mission_image,
.about .about_info .about_story .about_story_image {
  position: relative;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .about .about_info .about_mission .about_mission_image,
  .about .about_info .about_story .about_story_image {
    width: 60%;
  }
}

.about .about_info .about_mission .about_mission_image::before,
.about .about_info .about_story .about_story_image::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 15px;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 3px solid #888;
  z-index: -1;
}

.about .about_info .about_mission .about_mission_image img,
.about .about_info .about_story .about_story_image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .about .about_info .about_mission .about_mission_text,
  .about .about_info .about_story .about_story_text {
    text-align: center;
  }
}

.about .about_info .about_mission .about_mission_text h2,
.about .about_info .about_story .about_story_text h2 {
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.about .about_info .about_mission .about_mission_text p,
.about .about_info .about_story .about_story_text p {
  font-size: 14px;
}

.about .about_img {
  width: 100%;
  height: 470px;
  background-image: url("../../assets/logo.jpg");
  background-size: cover;
  position: relative;
  /* background-position: 280px -45px; */
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .about .about_img {
    background-position: 0;
  }
}

.about .about_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

/* .about .about_img::after {
  content: "About";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 60px;
  font-weight: 800;
} */

.about .about_info {
  color: #888;
  padding: 70px 0;
}

.about .about_info .about_story {
  margin-bottom: 150px;
}
