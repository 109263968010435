footer {
  background-color: #fff;
  /* padding: 60px 0 40px; */
  color: #666;
  box-shadow: #64646f33 0 20px 25px 25px;
}

footer a {
  display: inline-block !important;
}

.main-bg-color {
  background-color: #ffffff;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 25px 20px -20px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 20px 25px 25px;
}

footer ul li h6 {
  margin-bottom: 25px;
  font-weight: bold;
  color: #000;
}

footer ul li a {
  display: block;
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 300;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

footer ul li a:hover {
  color: var(--main-color);
}

footer ul li.imges img {
  width: 60px;
  height: 30px;
  background-color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.heading_meada {
  margin-bottom: 25px;
  color: #000;
}

.meada {
  display: flex;
  /* justify-content: center; */
  gap: 5px;
  align-items: end;
  /* padding: 0 12px; */
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .meada {
    margin-bottom: 20px;
    justify-content: center;
  }
}

.meada a {
  width: 46px;
  height: 46px;
  display: flex !important;
  /* place-content: center; */
  align-items: center;
  justify-content: center;
  /* background-color: #088178; */
  padding: 12px;
  /* color: rgb(29, 24, 24); */
  /* color: hsl(228, 8%, 95%); */
  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;

  /* z-index: 10000; */
}

.meada a svg {
  font-size: 24px;
  color: #df5090;
}

.copay {
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #cac8c8;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meada a:hover {
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.text-start-fremwork {
  text-align: start;
}

.dropdown-toggle::after {
  display: none !important;
}
