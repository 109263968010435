.heading_cat {
  color: #007e3d !important;
  font-weight: bold;
}

.products .swiper {
  width: 100%;
  /* height: 100%; */
}

.products .swiper-wrapper {
  padding: 20px 0 0 0 !important;
  /* margin-top: 20px; */
}

@media (min-width: 768px) {
  .products .swiper {
    padding: 0 28px !important;
  }
  body[dir="rtl"] .products .swiper-button-prev {
    right: -6px !important;
    left: auto;
  }
  body[dir="ltr"] .products .swiper-button-prev {
    left: -6px !important;
    right: auto;
  }
  body[dir="rtl"] .products .swiper-button-next {
    left: -6px !important;
    right: auto;
  }
  body[dir="ltr"] .products .swiper-button-next {
    right: -6px !important;
    left: auto;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* @media (max-width: 768) { */
.ProductsaAll .oldprice,
.ProductsaAll .currentPrice {
  font-size: 14px;
}

.ProductsaAll .image_desc {
  padding: 0 6px;
}
/* } */

.products .swiper-pagination {
  display: none !important;
}

.products .swiper-button-prev,
.products .swiper-button-next {
  opacity: 1 !important;
}

@media (max-width: 767px) {
  .products .swiper-button-prev,
  .products .swiper-button-next {
    display: none;
  }
}

.products .swiper .swiper-button-next:hover,
.products .swiper .swiper-button-prev:hover {
  color: #007e3d !important;
  /* opacity: 1 !important; */
}

.products .swiper-button-next:after,
.products .swiper-button-prev:after {
  font-size: 20px;
  font-weight: bold;
  color: #007e3d;
  /* opacity: 1 !important; */
}

/* add to cart */

.CartBtn {
  width: 140px;
  height: 40px;
  border-radius: 12px;
  border: none;
  background-color: rgb(255, 208, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
}

.IconContainer {
  position: absolute;
  left: -50px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  transition-duration: 0.5s;
}

.icon {
  border-radius: 1px;
}

.text_add_cart {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(17, 17, 17);
  z-index: 1;
  transition-duration: 0.5s;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.CartBtn:hover .IconContainer {
  transform: translateX(58px);
  border-radius: 40px;
  transition-duration: 0.5s;
}

.CartBtn:hover .text_add_cart {
  transform: translate(10px, 0px);
  transition-duration: 0.5s;
}

.CartBtn:active {
  transform: scale(0.95);
  transition-duration: 0.5s;
}
