.summary {
  background-color: #007e3d;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  position: relative;
  overflow: hidden;
  text-align: center;
  /* box-shadow: #64646f33 0 25px 20px -20px; */
  box-shadow: 0px 15px 10px -15px #111;
  z-index: 1;
}

.summary p {
  position: absolute;
  margin-top: 50px;
  user-select: none;
  transition: var(--main-transition);
  width: 100%;
}

.summary p.active {
  margin-top: 0;
}

.summary p a {
  color: var(--main-color) !important;
  cursor: pointer;
}