@import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";

.products-main-page {
  /* padding-top: 100px !important; */
  position: relative;
  /* display: flex; */
}
.products-main-page .categories_drawer-btn {
  position: absolute;
  top: 1rem;
  /* right: 5rem; */
}
.products-main-page .categories_drawer-btn.cat-btn {
  position: absolute;
  top: 1rem;
  margin-top: 2.6rem;
  /* right: 5rem; */
}
@media screen and (max-width: 768px) {
  .categories_drawer-btn-container {
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
  }
  .products-main-page .categories_drawer-btn {
    position: relative;
    /* top: 1rem; */
    margin: 0 1rem;
    /* right: 5rem; */
    font-size: 0.7rem;
  }
  .products-main-page .categories_drawer-btn.cat-btn {
    position: relative;
    /* top: 1rem; */
    margin-top: 0;
    /* right: 5rem; */
  }
}
@media screen and (max-width: 350px) {
  .products-main-page .categories_drawer-btn {
    margin: 0 0.5rem;
  }
}
.products_categories-drawer {
  display: flex;
  flex-direction: column;
}
.products_categories-drawer li {
  cursor: pointer;
  width: fit-content;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}
.products_categories-drawer li.active,
.products_categories-drawer li:hover {
  color: var(--main-color);
}

.products-main-page .products_categories {
  margin: 30px 0;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .products-main-page .products_categories {
    gap: 10px;
  }
}

.products-main-page .products_categories li {
  padding: 20px;
  position: relative;
  border: 1px solid #ccc;
  color: #333;
  transition: var(--main-transition);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .products-main-page .products_categories li:nth-child(even) {
    border-left: none;
    border-right: none;
  }
}

.products-main-page .products_categories li:hover,
.products-main-page .products_categories li.active {
  background-color: var(--main-color);
  color: #fff;
  box-shadow: 0 0 10px #999;
  border: none;
  transform: scale(1.1);
  z-index: 3;
  font-size: 17px;
}

.products-main-page .product {
  cursor: pointer;
  position: relative;
  transition: 0.7s;
  box-shadow: 0px 0px 30px rgba(13, 12, 13, 0.2);
}

.products-main-page .product:hover {
  box-shadow: 0px 0px 20px var(--main-color);
}

.products-main-page .product:hover img {
  transform: scale(1.1);
}

.products-main-page .product:hover .image_hold .product_option {
  opacity: 1;
}

.product .image_hold {
  height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product .image_hold img {
  transition: 0.7s;
  width: 90% !important;
  height: 100%;
  object-fit: contain;
}

.products-main-page .product_option {
  transition: var(--main-transition);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-main-page .product_option li {
  background-color: #fff;
  padding: 10px 20px;
  transition: var(--main-transition);
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-main-page .product_option li:hover {
  background-color: var(--main-color);
  box-shadow: 0 0 7px #fff;
}

.products-main-page .product_option li:hover svg {
  color: #fff;
}

.products-main-page .product_option li svg {
  color: var(--main-color);
  transition: var(--main-transition);
}

.products-main-page .product_option li:first-child {
  position: relative;
  padding: 30px;
}

.products-main-page .product_option li:first-child:hover::before,
.products-main-page .product_option li:first-child:hover.favicon::before {
  color: #fff;
}

.products-main-page .product_option li:first-child::before {
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  font-weight: 200;
  position: absolute;
  color: var(--main-color);
  transition: var(--main-transition);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.products-main-page .product_option li:first-child.favicon::before {
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  font-weight: 200;
  position: absolute;
  color: var(--main-color);
  transition: var(--main-transition);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.products-main-page .product .product_info {
  padding: 20px 0 10px;
  text-align: center;
  background-color: var(--alt-color);
}

.product_info .image_desc {
  font-weight: 500;
  font-size: 15px;
  color: #333;
  transition: var(--main-transition);
  /* تحديد 2 سطر */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* عدد الأسطر المراد عرضها */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 45px;
}

.products-main-page .product .product_info .image_desc:hover {
  color: var(--main-color);
}

.products-main-page .product .product_info .price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
}

.products-main-page .product .product_info .price .currentPrice {
  color: #777;
  transition: var(--main-transition);
}

.products-main-page .product .product_info .price .currentPrice:hover {
  color: #222;
}

.products-main-page .product .product_info .price .oldprice {
  text-decoration: line-through;
  color: var(--main-color);
}
