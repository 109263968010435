.Testimonials {
  background-color: var(--alt-color);
  margin-bottom: 40px;
}

.Testimonials .Testimonials_cont {
  width: 60%;
  margin: auto;
  padding: 40px;
}

@media (max-width: 991px) {
  .Testimonials .Testimonials_cont {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .Testimonials .Testimonials_cont {
    width: 100%;
    padding: 30px;
  }
}

.Testimonials .Testimonials_cont .test {
  padding: 0 30px;
}

@media (max-width: 767px) {
  .Testimonials .Testimonials_cont .test {
    padding: 10px;
  }
}

.Testimonials .Testimonials_cont .test .testi_text {
  font-size: 17px;
  font-weight: 500;
  /* margin-bottom: 45px; */
  color: #000000b5;
  /* border: 1px solid #eee; */
  padding: 0rem 2rem;
  min-height: 15rem;
  min-width: 35rem;
  width: 35rem;
  display: grid;
  place-items: center;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2); */
  position: relative;
}
.Testimonials .Testimonials_cont .test .testi_text .quotes {
  position: absolute;
  top: 0;
  right: 3rem;
  transform: translateY(-50%);
  width: 3.5rem;
  height: 3.5rem;
  color: white;
  display: grid;
  place-items: center;
  background-color: #fe5e3e;
}
.Testimonials .Testimonials_cont .test .testi_text img {
  /* max-height: 10rem; */
  width: 100%;
  /* height: 20rem; */
  object-fit: contain;
}

.Testimonials .Testimonials_cont .test .test_info {
  display: flex;
  gap: 15px;
}

.Testimonials .Testimonials_cont .test .test_info img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.Testimonials .Testimonials_cont .test .test_info .test_info_name {
  color: #666;
  font-weight: 500;
}

.Testimonials .Testimonials_cont .test .test_info .test_info_name p {
  color: #666;
  font-weight: 600;
  margin: 8px 0;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: var(--main-color);
}

/*  */

.action {
  top: -40px;
  right: 30px;
}

@media screen and (max-width: 467px) {
  /* .r-test {
    width: 20rem;
  } */
  .r-test .testi_text {
    min-width: 10rem !important;
    width: 16rem !important;
  }
}

@media screen and (min-width: 467px) and (max-width: 768px) {
  .Testimonials .Testimonials_cont .test .testi_text img {
    min-width: 25rem !important;
    height: 30rem;
  }
  .Testimonials .main_heading {
    font-size: 2rem;
  }
}

.feedbak {
  width: 400px !important;
  max-width: 100% !important;
}

@media (max-width: 560px) {
  .Testimonials .swiper-button-prev,
  .Testimonials .swiper-button-next {
    display: none !important;
  }
}
